import CollectionsIcon from '@mui/icons-material/Collections';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import { ROUTES } from 'routes';
import Divider from '@mui/material/Divider';
import QuizIcon from '@mui/icons-material/Quiz';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

export const PublicMenu = ({ onClick }:{ onClick: (to: string) => void }) => {

    const nav = (to: string) => {
        onClick(to);
    }

    const handleExternalClick = (link: string) => window.open(link);

    return (
        <>
            <MenuItem onClick={() => nav(ROUTES.home)}>
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                Home
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.episodes)} sx={{ display: { xs: 'block', md: 'none' }}}>
                <ListItemIcon>
                    <HeadphonesOutlinedIcon/>
                </ListItemIcon>
                Episodes
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.ringtones)}>
                <ListItemIcon>
                    <PhonelinkRingIcon/>
                </ListItemIcon>
                Ringtones
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.gallery)}>
                <ListItemIcon>
                    <CollectionsIcon/>
                </ListItemIcon>
                Gallery
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.about)}>
                <ListItemIcon>
                    <WavingHandOutlinedIcon/>
                </ListItemIcon>
                About
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.contact)}>
                <ListItemIcon>
                    <SendIcon/>
                </ListItemIcon>
                Contact Us
            </MenuItem>
            <Divider sx={{ display: { xs: 'block', md: 'none' }}} />
            <MenuItem onClick={() => handleExternalClick('https://nerdherfers.etsy.com')} sx={{ display: { xs: 'block', md: 'none' }}}>
                <ListItemIcon>
                    <ShoppingBagIcon/>
                </ListItemIcon>
                Merch
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.shop)} sx={{ display: { xs: 'block', md: 'none' }}}>
                <ListItemIcon>
                    <StorefrontIcon/>
                </ListItemIcon>
                Cigar Shop
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.arcade_blank)} sx={{ display: { xs: 'block', md: 'none' }}}>
                <ListItemIcon>
                    <QuizIcon/>
                </ListItemIcon>
                Take the Arcade Game Quiz!
            </MenuItem>
        </>
    )
}